import React, { lazy, Suspense } from "react";
import { Redirect } from "react-router-dom";
import { BaseWebUrl as WebUrl } from "../util/Constant";

// export const _LOGIN_ROUTE2 = {
//     path: WebUrl._URL_MAIN,
//     exact: false,
//     authRequired: false,
//     component: <Redirect to={WebUrl._URL_WELCOME} />,
// };

export async function fetchRoute(themeVar, type) {
    // CHRIS_LOCALE: Redirect if trailing slash detected
    if (window.location.pathname !== null && window.location.pathname !== "/" && window.location.pathname.endsWith("/")) {
        // // CHRIS TODO: Send 301 redirect for SEO
        // Replace isn't ideal inside react router because it will cause rerender
        // window.location.replace(window.location.pathname.slice(0, -1) + window.location.search);

        window.location.href = window.location.pathname.slice(0, -1);

        // <Redirect> doesn't work
        // return <Redirect to={window.location.pathname.slice(0, -1)} />;
    }

    const Login = lazy(async () => await import(`pages/${themeVar}/Login`));
    const Main = lazy(async () => await import(`pages/${themeVar}/Main`));
    const LiveChat = lazy(async () => await import(`pages/${themeVar}/LiveChat`));
    const Settings = lazy(() => import(`pages/${themeVar}/home/Settings`));
    const Kpi = lazy(async () => await import(`pages/${themeVar}/home/Kpi`));
    const News = lazy(async () => await import(`pages/${themeVar}/home/News`));
    const NewsDetails = lazy(
        async () => await import(`pages/${themeVar}/home/NewsDetails`)
    );
    const GameLobby = lazy(
        async () => await import(`pages/${themeVar}/game/GameLobby`)
    );
    const UnderMaintenance = lazy(
        async () => await import(`pages/${themeVar}/game/UnderMaintenance`)
    );
    const GameDetails = lazy(
        async () => await import(`pages/${themeVar}/report/GameDetails`)
    );
    const ContactUs = lazy(
        async () => await import(`pages/${themeVar}/home/ContactForm`)
    );
    const LiveChatPage = lazy(
        async () => await import(`pages/${themeVar}/home/LiveChat`)
    );
    const Transfer = lazy(
        async () => await import(`pages/${themeVar}/transaction/Transfer`)
    );
    const Promotion = lazy(
        async () => await import(`pages/${themeVar}/home/Promotion`)
    );
    const PromotionInfo = lazy(
        async () => await import(`pages/${themeVar}/home/PromotionInfo`)
    );
    const CustomPage = lazy(
        async () => await import(`pages/${themeVar}/home/CustomPage`)
    );
    const GameList = lazy(
        async () => await import(`pages/${themeVar}/game/GameList`)
    );
    const Referral = lazy(async () => await import(`pages/${themeVar}/home/Referral.js`));
    const Vip = lazy(async () => await import(`pages/${themeVar}/home/Vip.js`));
    const Rebate = lazy(async () => await import(`pages/${themeVar}/home/Rebate.js`));
    const RecentGames = lazy(async () => await import(`pages/${themeVar}/game/RecentGames.js`));
    const NotFound = lazy(async () => await import(`pages/${themeVar}/NotFound.js`));
    const IframeGamePage = lazy(
        async () => await import(`pages/${themeVar}/game/GamePage2`)
    );
    const SpinWheelList = lazy(
        async () => await import(`pages/${themeVar}/profile/SpinWheelList`)
    );
    const _LOGIN_ROUTE3 = [{
        // path: WebUrl._URL_MAIN,
        // exact: false,
        // authRequired: false,
        // component: <Redirect to={WebUrl._URL_HOME} />,
        path: WebUrl._URL_HOME,
        exact: true,
        title: "Home",
        component: <Login />,
    }];

    const _ROUTES = [
        {
            path: WebUrl._URL_MAIN,
            exact: true,
            title: "Main",
            authRequired: true,
            component: <Main />,
        },
        {
            path: WebUrl._URL_NOT_FOUND,
            exact: true,
            title: "404 Not Found",
            authRequired: true,
            component: <NotFound />,
        },
        {
            path: WebUrl._URL_LIVECHAT,
            exact: true,
            title: "LiveChat",
            authRequired: true,
            component: <LiveChat />,
        },
        {
            path: WebUrl._URL_UNDER_MAINTENANCE,
            exact: true,
            title: "Under Maintenance",
            authRequired: true,
            component: <UnderMaintenance />,
        },
        {
            path: WebUrl._URL_SETTINGS,
            exact: true,
            title: "Settings",
            authRequired: true,
            component: <Settings />,
        },
        {
            path: WebUrl._URL_GAME_LOBBY,
            exact: true,
            title: "Game Lobby",
            authRequired: true,
            component: <GameLobby />,
        },
        {
            path: WebUrl._URL_NEWS,
            exact: true,
            title: "News",
            authRequired: true,
            component: <News />,
        },
        {
            path: WebUrl._URL_NEWS_DETAILS,
            exact: true,
            title: "Detail",
            authRequired: true,
            component: <NewsDetails />,
        },
        {
            path: WebUrl._URL_CONTACT_US,
            exact: true,
            title: "Contact Us",
            authRequired: true,
            component: <ContactUs />,
        },
        {
            path: WebUrl._URL_LIVE_CHAT,
            exact: true,
            title: "Live Chat",
            authRequired: true,
            component: <LiveChatPage />,
        },
        {
            path: WebUrl._URL_PROMOTION,
            exact: true,
            title: "Promotion",
            authRequired: true,
            component: <Promotion />,
        },
        {
            path: WebUrl._URL_PROMOTION_INFO,
            exact: true,
            title: "Promotion Information",
            authRequired: true,
            component: <PromotionInfo />,
        },
        {
            path: WebUrl._URL_HOME,
            exact: true,
            title: "Home",
            component: <Login />,
        },
        {
            path: WebUrl._URL_GAME_LIST,
            exact: true,
            title: "Game List",
            authRequired: true,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_REFERRAL,
            exact: true,
            title: "Referral",
            authRequired: true,
            component: <Referral />,
        },
        {
            path: WebUrl._URL_VIP,
            exact: true,
            title: "Vip",
            authRequired: true,
            component: <Vip />,
        },
        {
            path: WebUrl._URL_REBATE,
            exact: true,
            title: "Rebate",
            authRequired: true,
            component: <Rebate />,
        },
        {
            path: WebUrl._URL_RECENT_GAMES,
            exact: true,
            title: "Recent Games",
            authRequired: true,
            component: <RecentGames />,
        },
        {
            path: WebUrl._URL_GAME_LIST_SPORTS,
            exact: true,
            title: "Sport",
            authRequired: true,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_GAME_LIST_SLOTS,
            exact: true,
            title: "Slots",
            authRequired: true,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_GAME_LIST_CASINO,
            exact: true,
            title: "Casino",
            authRequired: true,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_GAME_LIST_FISHHUNT,
            exact: true,
            title: "Fish Hunt",
            authRequired: true,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_GAME_LIST_KENO,
            exact: true,
            title: "Keno",
            authRequired: true,
            component: <GameList />,
        },
        {
            path: "/promo/:slug",
            exact: false,
            title: "Promotion Information",
            authRequired: true,
            component: <PromotionInfo />,
        },
        {
            path: "/pages/:slug",
            exact: false,
            title: "Custom Pages",
            authRequired: true,
            component: <CustomPage />,
        },
        {
            path: WebUrl._URL_GAME_LIST_E_SPORT,
            exact: true,
            title: "E-Sport",
            authRequired: true,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_IFRAME_GAME_PAGE,
            exact: true,
            title: "Game Page",
            authRequired: true,
            component: <IframeGamePage />,
        },
    ];

    // const createRoutesWithTrailingSlashRedirects = (routes) => {
    //     return routes.reduce((acc, route) => {
    //         const { path, title, exact, authRequired, component } = route;

    //         // Skip wildcard path '*' since it should not have a trailing slash redirect
    //         if (path === '*') {
    //             return acc.concat(route);
    //         }

    //         const routesWithRedirect = [
    //             {
    //                 path: `${path}/`,
    //                 exact: true,
    //                 title: title,
    //                 authRequired: false, // No auth required for redirects
    //                 component: <Redirect to={path} />,
    //             },
    //             route,
    //         ];

    //         return acc.concat(routesWithRedirect);
    //     }, []);
    // };

    // const locales = ['en', 'bn']; // Example locales

    // const generateLocaleRoutes = (routes) => {
    //     // return locales.flatMap(locale =>
    //     //     routes.map(route => ({
    //     //         ...route,
    //     //         path: `/${locale}${route.path}`,
    //     //     }))
    //     // );
    //     return routes
    // };

    // Do not need this anymore - better to do <Redirect> directly
    // const _SANITISED_ROUTES = createRoutesWithTrailingSlashRedirects(generateLocaleRoutes(_ROUTES));

    switch (type) {
        case "login":
            return ConvertToLocalePath(_LOGIN_ROUTE3);
            // if (themeVar == "template_1") return _LOGIN_ROUTE3;
            // else return _LOGIN_ROUTE;
            break;
        default:
            // return _ROUTES;
            return ConvertToLocalePath(_ROUTES);
            break;
    }
}

function ConvertToLocalePath(routes) {
    // CHRIS_LOCALE: Prepend locale to each path
    // Add :locale to all route paths to accommodate for example.com/:locale/pathname

    let _LOCALED_ROUTES = [];
    try {
        _LOCALED_ROUTES = routes.map((route) => {
            const { path } = route;
            const localePath = path.startsWith('/') ? `/:locale${path}` : `:locale${path}`;
            return {
                ...route,
                path: localePath,
            }
        });
    } catch (error) {
        console.error(error)
        throw error
    }

    return _LOCALED_ROUTES;
}