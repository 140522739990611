import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  SessionKey,
  _MAIN_WALLET_ID,
  DisplayDevice,
  TransactionStatus,
  AlertTypes,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import {
  showResponseMessage,
  setBusy,
  setIdle,
  bindMember,
  bindGames,
  setBanners,
  setGameSearchText,
  setGameQuery,
  setPageSize,
  setY,
  setThemeName,
  showMessage,
  setDepositModal,
  bindGamesRecommended,
  bindGamesFeatured,
  setLatestBets,
  setGameLobbyModal,
  setBusyLoading,
  bindCompanyIcon,
  setLoginModal,
  setRegisterModal,
  setDownlineModal,
  setShareReferralModal,
} from "../../redux/AppAction.js";
import { performLoginReturnMessage } from "../../redux/AuthAction.js";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
  createFormBody,
} from "../../util/Util";
import {
  map,
  find,
  filter,
  debounce,
  groupBy,
  chain,
  isEmpty,
  get,
  head,
  includes,
} from "lodash";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay } from 'swiper';
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
// import Spinner from "react-bootstrap/Spinner";
import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Alert,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import classnames from "classnames";
import PinInput from "react-pin-input";
import Header from "components/template_1/Header";
import Footer from "components/template_1/Footer";
import parse from "react-html-parser";
import LoadingGames from "components/template_1/LoadingGames";
import LoadingPlaceholder from "components/template_1/LoadingPlaceholder";
import SweetAlert from "react-bootstrap-sweetalert";
import GameLobbyModal from "components/template_1/GameLobbyModal";
import PlaceholderLoading from "react-placeholder-loading";
import UMIcon2 from "../../assets/img/icon/icon-repair.svg";
import FallBackImage from "components/template_1/FallBackImage";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from "react-loading-skeleton";
import SpeakerIcon from "components/custom/SpeakerIcon";
import ProviderIcon, { ProviderIconDisplay } from "components/custom/ProviderIcon";

const Login = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const hideShowPass = () => {
      setShowPassword(!showPassword);
  }

const {
	register: loginRegister,
	handleSubmit: loginHandleSubmit,
	errors: loginErrors,
} = useForm();


	const submitLoginForm = async (data, e) => {
		_dispatch(setBusyLoading(true));

		let loginRes = await _dispatch(
			performLoginReturnMessage(
				data.username,
				data.password,
				"en"
			)
		);
		let { type: loginType, message } = loginRes;

		if (loginType == "LoginSuccess") {
			_history.push(WebUrl._URL_MAIN)
		} else {

			_dispatch(
				showMessage({
					type: AlertTypes._ERROR,
					content: t(message),
				})
			);
		}
		_dispatch(setBusyLoading(false));
	};

  return (
    <>
     <div id="__nuxt">
          <div id="__layout">
            <div class="en">
              <div class="h-100vh text-center login">
                <img src="https://dl.erlangyao.com/images/web/assets/v.1.12/themes/jk/images/logo/logo.png" alt="logo" class="logo"/>
                <div class="card login-card">
                  <div class="card-body">
                    <h3>Login</h3>
                    <span>
                      <form class="" onSubmit={loginHandleSubmit(submitLoginForm)}>
                        <span>
                          <div class="floating-input mb-0 has-value" name="username" id="username">
                            <div role="group" class="input-group input-group-merge">
                              <div class="input-group-prepend">
                                <div class="input-group-text">
                                  <i class="las la-user"></i>
                                </div>
                              </div>
                              <label for="username">Username</label>
                              <input type="text" 
											ref={loginRegister({ required: true })}  placeholder=" " name="username" id="username" class="form-control"></input>
                            </div>
                            <br/>
                            <br/>
                          </div>
                        </span>
                        <span>
                          <div class="floating-input mb-0 has-value" name="password" id="password">
                            <div role="group" class="input-group input-group-merge">
                              <div class="input-group-prepend">
                                <div class="input-group-text">
                                  <i class="las la-lock-open"></i>
                                </div>
                              </div>
                              <label for="username">Password</label>
                              <input type={(showPassword?"text":"password")} 
											ref={loginRegister({ required: true })}  placeholder=" " name="password" id="password" class="form-control"></input>
                              <div class="input-group-append">
                                <div class="input-group-text" onClick={()=> {hideShowPass()}}>
                                  <i className={"las cursor-pointer "+(showPassword?"la-eye":"la-low-vision")}></i>
                                </div>
                              </div>
                            </div>
                            <br/>
                            <br/>
                          </div>
                        </span>
                        <div class="text-center mb-4">
                          <button type="submit" class="btn btn-loading btn-primary btn-block rounded-pill"> Login </button>
                        </div>
                      </form>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Login;
