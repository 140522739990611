import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	WebUrl,
	ApiUrl,
	ApiKey,
	TransactionType,
	BankingChannel,
	PanelType,
	SessionKey,
} from "../../../util/Constant";
import { map, isNumber, debounce, toString, includes } from "lodash";
import {
	numberWithCurrencyFormat,
	stringIsNullOrEmpty,
	createMultiPartFormBody,
	abbreviateNumber,
} from "../../../util/Util";
import ApiEngine from "../../../util/ApiEngine.js";
import { useSelector, useDispatch } from "react-redux";
import {
	setBusy,
	setIdle,
	setDepositModal,
	showMessage,
	showResponseMessage,
	setBusyLoading,
	setMyProfileModal,
} from "../../../redux/AppAction";
import moment from "moment";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import { Modal, ModalBody, Alert } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import classNames from "classnames";
import { Spinner } from "react-bootstrap";

/// <summary>
/// Author: -
/// </summary>
const InstantPayDeposit = (props) => {
	var { t, i18n } = useTranslation();
	var _history = useHistory();
	const [file, setFile] = useState();
	let _dispatch = useDispatch();
	const walletsDetails = useSelector((state) => state.appState.walletsDetails);
	const [receiptReference, setReceiptReference] = useState("");
	const [selected, setSelected] = useState(0);
	const [amount, setAmount] = useState(0.0);
	const [paidAmount, setPaidAmount] = useState(0);
	const [isReloadSuccess, setIsReloadSuccess] = useState(false);
	const [reloadDate, setReloadDate] = useState();
	const [min, setMin] = useState(0);
	const [max, setMax] = useState(0);
	const [bankAccountId, setBankAccountId] = useState();
	const [isDisabled, setIsDisabled] = useState(false);
	const [bankName, setBankName] = useState("");
	const [accNumber, setAccNumber] = useState("");
	const [label, setLabel] = useState("");
	const [bankref, setBankRef] = useState("");
	const [showCodeAlert, setShowCodeAlert] = useState(false);
	const [bonusDeposit, setBonusDeposit] = useState("");
	const [bankAccountOption, setBankAccountOption] = useState([]);
	const [selectedEwallet, setSelectedEwallet] = useState();
	const [isDirect, setIsDirect] = useState(true);
	const[displayNotes,setDisplayNotes] = useState(false);
	
	const companyCurrency = useSelector((state) => state.appState.companyCurrency);
	const depositPresetAmt = useSelector((state) => state.appState.depositPresetAmt);
	const amountState = [
		{ amount: "" },
		{ amount: "" },
		{ amount: "" },
		{ amount: "" },
		{ amount: "" },
		{ amount: "" }
	  ];
	const [addAmountList, setAddAmountList] = useState(amountState);

	const walletOption = [
		{
			value: "bkash",
			label: "Bkash"
		},
		{
			value: "rocket",
			label: "Rocket"
		},
		{
			value: "nagad",
			label: "Nagad"
		},
	];


	const _ALERT_TIMEOUT = 1600;
	var _accNumberRef = useRef();
	var _labelRef = useRef();
	var _bankRef = useRef();
	const [copied, setCopied] = useState("");
	const [toobj, setToobj] = useState(null);
	const memberData = useSelector((state) => state.appState.member);
    const [instantBankSetting, setInstantBankSetting] = useState()
    const [instantPayGatewayId, setinstantPayGatewayId] = useState()

	const [headerMsg, setHeaderMsg] = useState("");
	const [hasPendingTxn, setHasPendingTxn] = useState(false);
	const [transType, setTransType] = useState("");
	const [countdownPeriod, setCountdownPeriod] = useState(0);
	const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
	const [countdownTime, setCountdownTime] = useState();
	const [countdownStr, setCountdownStr] = useState("");
	const [loadingSpinner, setLoadingSpinner] = useState(true);
	const [phoneNumber, setSelectedPhoneNumber] = useState();
	const [phoneOption, setPhoneOption] = useState([]);
	const [requirePhone, setRequirePhone] = useState(false)

	useEffect(()=>{

		const depositPresetAmtArray = depositPresetAmt.split(',');

		const updatedData = addAmountList.map((item, index) => {
			return { ...item, amount: depositPresetAmtArray[index] || "" };
		  });
		setAddAmountList(updatedData);


	},[depositPresetAmt])

	useEffect(() => {
		init();
		getMemberEwalletAccounts();
	}, []);

	useEffect(() => {

        // if(props.instantBankSetting){
        //     setBankAccountOption(props.instantBankSetting.instantBankSettingModel)
        // }
	}, props);
	useEffect(()=>{
		if(instantBankSetting){
			
			if(includes(instantBankSetting?.bankCode,'Ewallet') || includes(instantBankSetting?.bankCode,'ewallet')){
				setIsDirect(false)
				setRequirePhone(false)
	
			}else if(includes(instantBankSetting?.bankCode,'FPay') || includes(instantBankSetting?.bankCode,'fpay')){
				setIsDirect(true)
				setRequirePhone(true)
			}
			else{
				setIsDirect(true)
				setSelectedEwallet('bkash')
				setRequirePhone(false)
	
			}
		}
	
	},[instantBankSetting])
	// useEffect(() => {
	// 	if (!stringIsNullOrEmpty(bankAccountId) && bankAccountId > 0) {
	// 		getBankAccInfo();
	// 	}
	// }, [bankAccountId]);

	async function init() {

		var responseJson = await ApiEngine.get(
			ApiUrl._API_GET_PENDING_TRANSACTION
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			if (responseJson[ApiKey._API_DATA_KEY] != null) {
				clearTimeout(countdownTime);
				let tempS = parseInt(responseJson[ApiKey._API_DATA_KEY]["timeSpan"]);
				setTransType(responseJson[ApiKey._API_DATA_KEY]["transType"]);
				setTimeDiffSeconds(tempS);
				setCountdownPeriod(tempS < 0 ? 0 : tempS);
				setHasPendingTxn(true);
				setHeaderMsg(t("NEW_TXN_PROHIBITED"));
			}else{
				const instantBankSettingModelList = props.instantBankSetting?.instantBankSettingModel
				let bankAccountList = [];
				const instantBankSettingModel = props.instantBankSetting?.instantBankSettingModel[0]
		
				if(instantBankSettingModelList){
					instantBankSettingModelList.forEach(function (bankAccount) {
						bankAccountList.push({
							label: bankAccount.name,
							value: bankAccount.id,
							// accNumber: bankAccount.accountNumber,
							min: bankAccount.minimumDeposit,
							max: bankAccount.maximumDeposit,
							bankName: bankAccount.name,
						});
					});
		
					setBankAccountOption(bankAccountList);
		
					setMin(instantBankSettingModel.minimumDeposit);
					setMax(instantBankSettingModel.maximumDeposit);
					setBankName(instantBankSettingModel.name);
			  
		
					generateReferenceReceipt();
		
					
					setDisplayNotes(props.instantPayGatewayNotes?true:false);
				   
		
					setBankAccountId(instantBankSettingModel.bankId)
		
					setInstantBankSetting(instantBankSettingModel)
					setinstantPayGatewayId(props.instantBankSetting.instantPayGatewayId)
		
				}
		
			}

			setLoadingSpinner(false);
		} else {
			_dispatch(
				showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
			);
		}

       
		

        // let res = await ApiEngine.get(
		// 	ApiUrl._API_GET_PAYMENT_GATEWAY_INFO_BY_ID + "?id=" + props.instantBankSettingId
		// );
        // console.log(res);

		// if (!res[ApiKey._API_SUCCESS_KEY]) {
		// 	throw res[ApiKey._API_MESSAGE_KEY];
		// }
		// let responseJson = await ApiEngine.get(
		// 	ApiUrl._API_GET_BANK_ACCOUNT_2 + "?deposit=" + true
		// );
		// if (responseJson[ApiKey._API_SUCCESS_KEY]) {
		// 	let bankAccountList = [];
		// 	responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
		// 		bankAccountList.push({
		// 			label: bankAccount.accountHolderName,
		// 			value: bankAccount.id,
		// 			accNumber: bankAccount.accountNumber,
		// 			min: bankAccount.minimumDeposit,
		// 			max: bankAccount.maximumDeposit,
		// 			bankName: bankAccount.bankName,
		// 		});
		// 	});
        //     console.log(bankAccountList)
		// 	setBankAccountOption(bankAccountList);
		// }
	}
	
	const debouncedAmount = useCallback(
		debounce((value) => {
		  setSelected(value)
		}, 500), // 500ms debounce time
		[]
	  );

	async function enterAmount (e) {
	
		let amount = e.target.value
			.replace(
				/[a-zA-Z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?(kr|$|£|€)]*/g,
				""
			)
			.trim();
			

		if (amount == "") {
			amount = 0;
		}

		let value = 0
		if (companyCurrency === "PKR") {
			
			value = Number(parseFloat(amount)).toFixed(0)
		}
		else{
			value = Number(parseFloat(amount) / 100).toFixed(2);

		}
		

		// let value = Number(parseFloat(amount) / 100).toFixed(2);

		if (parseFloat(value) > max) {
			value = max;
		}
		e.target.setSelectionRange(value.length, value.length);
		setAmount(value);
		// console.log(parseInt(value))
		debouncedAmount(toString(parseInt(value)));
							
	}

	async function getBankAccInfo() {
		_dispatch(setBusy());
		let responseJson = await ApiEngine.get(
			ApiUrl._API_GET_BANK_ACCOUNT_BY_BANK_ID + "?bankId=" + bankAccountId
		);

		if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
			throw responseJson[ApiKey._API_MESSAGE_KEY];
		}

		setMin(responseJson[ApiKey._API_DATA_KEY][0]["minimumDeposit"]);
		setMax(responseJson[ApiKey._API_DATA_KEY][0]["maximumDeposit"]);
		setBankName(responseJson[ApiKey._API_DATA_KEY][0]["bankName"]);
		setAccNumber(responseJson[ApiKey._API_DATA_KEY][0]["accountNumber"]);
		setLabel(responseJson[ApiKey._API_DATA_KEY][0]["accountHolderName"]);
		//setBankRef(Math.floor(Math.random() * 9000 + 1000));

		_dispatch(setIdle());
	}



	const truncate_text = (text) => {
		if (text.length > 15) {
			return text.slice(0, 15) + " . . .";
		}
		return text;
	};

	const formatBytes = (bytes, decimals = 2) => {
		if (!+bytes) return "0 Bytes";

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	};

	const handleClick = (item) => {
		let total = parseFloat(item);
		if (min > total) { total = min; }
		if (max > 0) {
			if (total > max) {
				setSelected(0);
				total = max;
			} else {
				setSelected(item);
			}
			setAmount(total);
		} else {
			_dispatch(showResponseMessage(false, "PLEASE_SELECT_BANK"));
		}
	};

	async function handleRedirect() {
		// let params = {
		// 	transactionTypeId: TransactionType._DEPOSIT,
		// 	amount: parseFloat(amount),
		// 	channel: BankingChannel._CHANNEL_OFFLINE_BANKING,
		// 	panel: PanelType._MEMBERSITE,
		// 	bankAccountId: bankAccountId,
		// 	receiptReference: "INV" + bankref,
		// };

        let params = {
            transactionTypeId: TransactionType._DEPOSIT,
            bankId: instantBankSetting.bankId,
            amount: parseFloat(amount),
            bankCode: selectedEwallet,
            channel: instantPayGatewayId,
            panel: PanelType._MEMBERSITE,
            instantPayBankSettingId:instantBankSetting.id,
            currency: instantBankSetting.currency,
            instantPay: true,
            receiptReference: bankref,
            bankAccountId: bankAccountId,
        };

	
		// if (!stringIsNullOrEmpty(bankAccountId)) {
		// 	params["bankAccountId"] = bankAccountId;
		// }

		// if (!stringIsNullOrEmpty(file)) {
		// 	params["receipt"] = file;
		// }
		if (!stringIsNullOrEmpty(phoneNumber)) {
			params["phoneNumber"] = phoneNumber.label;
		} 

		if (!stringIsNullOrEmpty(bonusDeposit) && bonusDeposit != "-1") {
			params["depositBonusId"] = bonusDeposit;
		} else if (bonusDeposit === "-1") {
			params["depositBonusId"] = "";
		}

		// console.log(params)
		// return false
		_dispatch(setBusyLoading(true))
		let responseJson = await ApiEngine.post(
			ApiUrl._API_CREATE_TRANSACTION,
			createMultiPartFormBody(params)
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setPaidAmount(params.amount);
			setIsReloadSuccess(true);
			setReloadDate(moment().format("DD MMM YYYY, hh:mm A"));

			if (window.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
				  JSON.stringify({
					action: "payment",
					url: responseJson[ApiKey._API_DATA_KEY]["redirectUrl"],
				  })
				);
			  } else {
				window.location.href =
				  responseJson[ApiKey._API_DATA_KEY]["redirectUrl"];
			  }

            // console.log(responseJson)
			// _dispatch(setDepositModal(false));
			// _dispatch(
			// 	showMessage({
			// 		custom: true,
			// 		customIcon: (
			// 			<img
			// 				className="m-auto"
			// 				src={require("../../../assets/img/new_icons/checked.png")}
			// 				height="120px"
			// 				width="120px"
			// 				alt="Custom Icon"
			// 			/>
			// 		),
			// 		content: t("WE_ARE_VERIFYING_YOUR_PAYMENT"),
			// 	})
			// );
		} else {
			setIsDisabled(false);
			setAmount(0);
			setSelected(0);
			setFile();
			setReceiptReference("");
			// _dispatch(setBusyLoading(false))
			_dispatch(
				showResponseMessage(
					responseJson[ApiKey._API_SUCCESS_KEY],
					t(responseJson[ApiKey._API_MESSAGE_KEY])
				)
			);
		}
		_dispatch(setBusyLoading(false))

	}

	const copyItem = (item, type) => {
		setCopied(type);
		navigator.clipboard.writeText(item.current.innerText);

		setShowCodeAlert(true);
		if (toobj != null) clearTimeout(toobj);
		setToobj(setTimeout(() => { setCopied(""); setTimeout(null); }, [_ALERT_TIMEOUT]));

	};

	const generateReferenceReceipt = async () =>{
		let receiptReference=`INV${Math.floor(100000 + Math.random() * 900000)}`;
		// let responseJson;
		// let isUnique = false;
		
		// while (!isUnique) {
		// 	receiptReference = `INV${Date.now()}${Math.floor(Math.random() * 1000000)}`;
			
		// 	try {
		// 		responseJson = await ApiEngine.get(
		// 		ApiUrl._API_CHECK_TRANSACTION_REFERENCE + "?reference=" + receiptReference
		// 		);
			
		// 		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
		// 			isUnique = true;
		// 		} 
		// 		else {
		// 		  console.log(`Collision detected for ${receiptReference}, generating a new one.`);
		// 		}
		// 	}catch(error){
		// 		_dispatch(
		// 			showResponseMessage(
		// 				responseJson[ApiKey._API_SUCCESS_KEY],
		// 				responseJson[ApiKey._API_MESSAGE_KEY]
		// 			)
		// 		);
		// 		break;
		// 	}
		//   }
		setBankRef(receiptReference);
	}

	async function getMemberEwalletAccounts() {
		let responseJson = await ApiEngine.get(
			// ApiUrl._API_GET_MEMBER_PHONE_NUMBERS + "?isVerifyRequired=true"
			ApiUrl._API_GET_MEMBER_PHONE_NUMBERS
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			console.log(responseJson)
			let phoneNumberList = [];
			responseJson[ApiKey._API_DATA_KEY].map((phoneNumber) => {
				phoneNumberList.push({
					label: phoneNumber.phoneNumber,
					value: phoneNumber.id,
					id: phoneNumber.id, // CHRIS 20241206: added this here for convenience
					verified: phoneNumber.verified,
					phoneNumType: "memberEwalletAccounts", // member or memberEwalletAccounts
					accountHolderName: phoneNumber.accountHolderName
				});
			});
			setPhoneOption(phoneNumberList);
		} else {
			throw responseJson[ApiKey._API_MESSAGE_KEY];
		}
	}

	function handleSelectedPhoneNumber(value) {
		const phoneNumberData = phoneOption.filter((x) => x.value == value)[0];

		setSelectedPhoneNumber(phoneNumberData);
		// setValue(value);

	}


	return (
		<>
		   {loadingSpinner &&
				<div
					style={{
						textAlign: "center",
						marginTop: "25px",
						marginBottom: "20px",
					}}
				>
					<Spinner
						animation="border"
						role="status"
						size="sm"
						style={{
							verticalAlign: "middle",
							width: "2rem",
							height: "2rem",
						}}
					>
						<span className="sr-only">{t("LOADING")}...</span>
					</Spinner>
				</div>
			}

           { !loadingSpinner && hasPendingTxn && (
				<div className="mt-3 mb-3 reminder-red">
					<div className="first">
						<div className="first">
							<img
								src={require("../../../assets/img/new_icons/red.png")}
								className="img-responsive"
								alt="icon"
							/>
						</div>
						<div>
							<p className="font14 mb-0 f-weight-500">
								{timeDiffSeconds > 0
									? t("PLEASE_WAIT") +
									" " +
									countdownStr +
									" " +
									(transType === "Deposit"
										? t("FOR_DEPOSIT_APPROVAL")
										: t("FOR_WITHDRAWAL_APPROVAL"))
									: headerMsg}
							</p>
						</div>
					</div>
				</div>
			)}
            {!loadingSpinner && !hasPendingTxn && (
            <>
			<div className="t3-reload-bank-list-box d-block position-relative">
				<div className="reload-bank-details d-flex justify-content-between align-items-center">
					<span className="font14">{t("BALANCE")}</span>
					<span className="font14">
						{numberWithCurrencyFormat(
							walletsDetails.currentWalletBalance,
							2,
							true
						)}
					</span>
				</div>
				<div className="reload-bank-details d-flex justify-content-between align-items-center">
					<span className="font14">{t("MIN_DEPOSIT")}</span>
					<span className="font14">
						{/* {numberWithCurrencyFormat(min, 2, true)} */}
						{min ?
							numberWithCurrencyFormat(
								min,
								0,
								true
							)
						:
							"-"
						}
					</span>
				</div>
				{displayNotes &&
				(<>
				<div className="border-top mt-2 mb-2"></div>
				<div className="reload-bank-details">
					<span className="yellow-note-title">{t("NOTES")} : </span>
					<p className="font14 mb-0">
						{t("UPLOAD_DESCRIPTION")}
					</p>
				</div>
				</>
				)}
			</div>
			{memberData.isActivedPromoExist &&
				<div className="mt-3 mb-3 reminder-red">
					<div className="first">
						<div className="first">
							<img
								src={require("assets/img/new_icons/red.png")}
								className="img-responsive"
								alt="icon"
							/>
						</div>
						<div>
							<p className="text-left font14 mb-0 f-weight-500">
								{t("NEW_TRANSACTION_NOT_QUALIFY_FOR_PROMO")}
							</p>
						</div>
					</div>
				</div>
			}

			<form className="mt-4 mb-0 custom-form">
				<div className="mb-3">
					{
						!isDirect && (
							<select
								className="t3-deposit-option-select"
								value={selectedEwallet}
								placeholder={t("PLEASE_SELECT_EWALLET")}
								onChange={(e) => {
									setSelectedEwallet(e.target.value)
				
								}}
							>
								<option className="t3-deposit-option-select"  value="" disabled selected>
									{t("PLEASE_SELECT_EWALLET")}
								</option>
								
								{walletOption.map((option, index) => {
									return (
										<option
											className="t3-deposit-option-value"
											value={option.value}
											key={index}
										>
											{option.label}
										</option>
									);
								})}
							</select>
						)
					}
					
				</div> 
						{
							requirePhone && (
								<div id="t3-custom-modal-settings" className="col-12 p-0 mb-3">
								<select
									className="t3-withdrawal-option-select"
									// className={
									// 	// !hasRolloverBal &&
									// 	_userData.walletBalance > numberWithCurrencyFormat(parseFloat(0), 2, true) 
									// 	// && stringIsNullOrEmpty(memberBankAccounts)
									// 		? "t3-withdrawal-option-select"
									// 		: "t3-withdrawal-option-select disabled"
									// }
									name="phoneNumber"
									// ref={register({
									// 	required: "FIELD_REQUIRED",
									// })}
									defaultValue={phoneNumber}
									placeholder={t("PLEASE_SELECT_PHONE_NUMBER")}
									onChange={(e) => {
										// setValue("phoneNumber",e.target.value)
										handleSelectedPhoneNumber(e.target.value);
										// clearErrors("phoneNumber");
									}}
									required
								>
									<option value="" disabled selected>
										{t("PLEASE_SELECT_PHONE_NUMBER")}
									</option>
									{phoneOption.map((option, index) => {
										return (
											<option
												className="t3-withdrawal-option-value"
												value={option.value}
												key={index}
											>
												{option.label}
											</option>
										);
									})}
								</select>
								{/* {errors.phoneNumber && (
									<div className="d-flex align-items-center error-msg mt-1">
										<div className="font12 text-lgray3 second text-customred">
											{t(errors.phoneNumber.message)}
										</div>
									</div>
								)} */}
								{
									phoneOption.length < 3 && (
										<div className="add-wallet-button-container mt-2">
											<button className="text-button" onClick={() => { _dispatch(setMyProfileModal(true)); _dispatch(setDepositModal(false)); }}>{t("ADD_PHONE_NUMBER")}</button>
										</div>
									)
								}
								
							</div>
							)
						}
						

				

				<div className="t3-deposit-box-wrapper">
					<label className="mb-1">{t("DEPOSIT_AMOUNT_TITLE")}</label>
					<div className="t3-deposit-first-recharge-msg mb-3"></div>
					<div className="t3-deposit-amount-list-box mt-3">
						{addAmountList &&
							addAmountList.length &&
							map(addAmountList, (item, index) => {
								if(item.amount != ""){
									const formattedCurrency = numberWithCurrencyFormat(item.amount,0 ,true);
									return (
										<div
											key={index}
											className={
												"item " + ((item.amount > max || min > item.amount) ? "disabled ":"") + (item.amount === selected ? "active" : "")
											}
											onClick={() => handleClick(item.amount)}
											// style={{ cursor: "pointer" }}
										>
											<div className="bg">
												<img
													src={require("../../../assets/img/template_1/deposit-light.png")}
													className="img-responsive"
													alt="deposit"
												/>
											</div>
											<div className="first">{abbreviateNumber(formattedCurrency)}</div>
										</div>
									);
								}
							})}
					</div>

					<div className="t3-deposit-form-style-box mt-4">
						<div className="t3-deposit-input-style-box">
							<div className="first">{companyCurrency}</div>
							<div className="second">
								<input
									type="text"
									placeholder={
										"Enter the amount ("+ companyCurrency +" " + min + " - "+ companyCurrency + " " + max + ")"
									}
									value={
										companyCurrency === "PKR"
											? Number(amount) > 0
												? parseInt(amount) // Show without decimals for AUD
												: 0
											: Number(amount) > 0
											? Number(amount).toFixed(2) // Show decimals for other currencies
											: 0
									}
									onChange={enterAmount} 
								/>

							</div>

						</div>
						<div className="mt-2 pr-1 text-right">{min>0 && max>0 ? companyCurrency +" " + min + " - "+ companyCurrency + " " + max : <>&nbsp;</> }</div>

					</div>
				</div>

				{!stringIsNullOrEmpty(bankAccountId) && bankAccountId > 0 && (
					<>
						<div
							className="t3-deposit-modal-body bank-info"
							style={{ paddingLeft: 0, paddingRight: 0 }}
						>
						{/*{ showCodeAlert && <div className="content content-full-width" style={{ position: "fixed", top: "10px", width: "100%", left: "0"}}>
								<div className="container">
									<div className="mt-3 mb-3 reminder-green">
										<div className="first">
											<div className="first">
												<img
													src={require("../../../assets/img/new_icons/green.png")}
													className="img-responsive"
													alt="icon"
												/>
											</div>
											<div>
												<p className="font14 mb-0 f-weight-500">
													{t("COPY_SUCCESS")}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>}*/}
					
							<div className="t3-reload-bank-list-box">
								<div className="reload-bank-details">
									<span className="font12 text-lgray2">{t("BANK_NAME")}</span>
									<p className="mb-0 font16">{bankName}</p>
								</div>
							</div>
							<hr className="bank-info-separator-line" />
							{/* <div className="t3-reload-bank-list-box">
								<div className="reload-bank-details">
									<span className="font12 text-lgray2">
										{t("ACCOUNT_NUMBER")}
									</span>
									<p className="mb-0 font16" ref={_accNumberRef}>
										{accNumber}
									</p>
								</div>
								<div
									onClick={() => copyItem(_accNumberRef, "acc")}
									style={{ cursor: "pointer" }}
								>
									<span className={(copied === "acc" ? "copied fade-out" : "copied")}>{(copied === "acc" ? "Copied" : "")}</span> <img
										src={require("../../../assets/img/new_icons/copy.png")}
										className="img-responsive"
										alt="icon"
									/>
								</div>
							</div> */}
							{/* <hr className="bank-info-separator-line" /> */}
							{/* <div className="t3-reload-bank-list-box">
								<div className="reload-bank-details">
									<span className="font12 text-lgray2">
										{t("ACCOUNT_HOLDER_NAME")}
									</span>
									<p className="mb-0 font16" ref={_labelRef}>
										{label}
									</p>
								</div>
								<div
									onClick={() => copyItem(_labelRef, "label")}
									style={{ cursor: "pointer" }}
								>
									<span className={(copied === "label" ? "copied fade-out" : "copied")}>{(copied === "label" ? "Copied" : "")}</span> <img
										src={require("../../../assets/img/new_icons/copy.png")}
										className="img-responsive"
										alt="icon"
									/>
								</div>
							</div> */}
							{/* <hr className="bank-info-separator-line" /> */}
							<div className="t3-reload-bank-list-box">
								<div className="reload-bank-details">
									<span className="font12 text-lgray2">{t("REFERENCE")}</span>
									<p className="mb-0 font16" ref={_bankRef}>
										{bankref}
									</p>
								</div>
								<div
									onClick={() => copyItem(_bankRef, "ref")}
									style={{ cursor: "pointer" }}
								>
									{copied === "ref" ?
										(<span className="copied">
											{t("COPIED")}
										</span>) : (<img
											src={require("../../../assets/img/new_icons/copy.png")}
											className="img-responsive"
											alt="icon"
										/>)
									}
								</div>
							</div>
						</div>

						{/* <div className="t3-deposit-modal-body upload-receipt">
							<p className="font14">{t("UPLOAD_DESCRIPTION")}</p>

							{!file && (
								<label htmlFor="upload" className="t3-upload-file-box">
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											gap: "10px",
										}}
									>
										<div className="t3-upload-img">
											<VIcon
												icon={themeIcons.wallet}
												className="vicon theme-icon-size-20"
											/>
										</div>
										<div className="font16">{t("TAP_TO_UPLOAD_FILE")}</div>
									</div>
								</label>
							)}
							{file && (
								<div className="t3-upload-file-box">
									<div className="t3-upload-file-box-content">
										<div
											className="d-flex justify-content-start align-items-center"
											style={{
												width: "10%",
											}}
										>
											<VIcon
												icon={themeIcons.image}
												className="vicon theme-icon-size-20"
											/>
										</div>
										<label
											htmlFor="upload"
											style={{
												color: "#fff",
												width: "75%",
												display: "flex",
												flexDirection: "column",
												alignItems: "start",
												justifyContent: "center",
												marginBottom: "0",
												marginLeft: "0.5rem",
											}}
										>
											<div style={{ fontSize: "15px" }}>
												{truncate_text(file.name)}
											</div>
											<div style={{ color: "#94a3b8" }}>
												{formatBytes(file.size)}
											</div>
										</label>
										<div
											style={{
												display: "flex",
												justifyContent: "end",
												alignItems: "center",
												width: "15%",
											}}
										>
											<VIcon
												icon={themeIcons.close}
												className="vicon theme-icon-size-20"
												onClick={() => setFile()}
											/>
										</div>
									</div>
								</div>
							)}
							<input
								type="file"
								id="upload"
								hidden
								onChange={(e) => setFile(e.target.files[0])}
								value=""
								accept="image/png, image/jpeg,image/jpg"
							/>
						</div> */}
					</>
				)}

				<div
					className="t3-settings-button-box mt-5"
					// style={{ marginBottom: "15%" }}
				>
					{!stringIsNullOrEmpty(bankAccountId) && bankAccountId > 0 && (
						<div className="text-center">
							<button
								type="button"
								className="t3-custom-gray-btn"
								onClick={() => {
									_dispatch(setDepositModal(false));
								}}
								style={{ cursor: "pointer" }}
							>
								{t("CANCEL")}
							</button>
						</div>
					)}
					{!isDisabled && (
						<a
							className={`btn t3-custom-light-btn d-flex align-items-center ${
								(!isDirect && !selectedEwallet) ||
								amount <= parseFloat(min) - 0.01
									? "disabled"
									: ""
							}`}
							onClick={() => {
								handleRedirect();
								setIsDisabled(true);
							}}
							style={{ cursor: "pointer" }}
						>
							{t("SUBMIT")}
						</a>
					)}

					{isDisabled && (
						<a className="btn t3-custom-light-btn d-flex align-items-center disabled">
							{t("SUBMIT")}
						</a>
					)}
				</div>
			</form>
			</>)}
		</>
	);
};

export default InstantPayDeposit;
