import { themeIcons } from "config/theme";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import { showResponseMessage, bindMember, setMyProfileModal } from "../../redux/AppAction.js";
import { map, find, debounce, isEmpty } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { createFormBody, stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util.js";
import moment from "moment";
import DatePicker from "react-bootstrap-daterangepicker";
import PinInput from "react-pin-input";
import Skeleton from "react-loading-skeleton";
import VerifyPhoneNumberModal from "./VerifyPhoneNumberModal";

const MyProfileModal = ({popout}) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();

  const [alertIcon, setAlertIcon] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const profileModal = useSelector((state) => state.appState.profileModal)

  const memberData = useSelector((state) => state.appState.member);
  const companyIcons = useSelector((state) => state.appState.companyIcons);

  const [birthDate, setBirthDate] = useState(
    moment().subtract(18, "years").format("DD-MM-YYYY")
  );

  const maxDate = moment().subtract(18, "years");
  const minDate = moment().subtract(100, "years");

  const [isEditBirthDate, setIsEditBirthDate] = useState(false);
  const [memberBankAccounts, setMemberBankAccounts] = useState({});
  var { heartBeat } = useSelector((state) => state.authState);

  const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false);
  const [showAddPhoneNumberModal, setShowAddPhoneNumberModal] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [showVerifyOtpError, setShowVerifyOtpError] = useState(false);
  const [sentCode, setSentCode] = useState(false);

  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [TACcountdownPeriod, setTACCountdownPeriod] = useState(0);
  const [TACtimer, setTACtimer] = useState(setTimeout(() => { }, 60000));
  const [TACtimedOut, setTACtimedOut] = useState(true);
  const [attemp, setAttemp] = useState(1);
  const elePin = useRef(null);
  const [pinInputDisabled, setPinInputDisabled] = useState(false);
  const [tempPinNumber, setTempPinNumber] = useState();
  const [pinLoading, setPinLoading] = useState(false);

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(false);
  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState("");
  const PhoneNumberPrefix = useSelector((state) => state.appState.dialingCodes);
  const [memberEwalletAccounts, setMemberEwalletAccounts] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedPhoneNumberId, setSelectedPhoneNumberId] = useState("");
  const [showDeletePhoneNumberModal, setShowDeletePhoneNumberModal] = useState(false);
  const [phoneNumType,setPhoneNumType]=useState("");

  const _COUNTDOWN_PERIOD = 120;
  const _TAC_VALID_TIMER = 60000;
  const _SECONDS_IN_MS = 1000;
  const _TAC_TIMEOUT_IN_MINUTES = 1;

  const {
    register: completeProfile,
    handleSubmit: completeProfileHandleSubmit,
    errors: completeProfileErrors,
    setError: setCompleteProfileError,
  } = useForm();

  const {
    register: ewalletRegister,
    handleSubmit: ewalletHandleSubmit,
    errors: ewalletErrors,
    setError: ewalletError,
  } = useForm();

  useEffect(() => {
    if (profileModal) {
      getMemberEwalletAccounts();
    }
  }, [profileModal]);

  useEffect(() => {
    // Ensure memberData has content before checking birthdate
    if (memberData && Object.keys(memberData).length > 0) {
      checkBirthDate()
    }
  }, [memberData])

  useEffect(() => {
    if (heartBeat) {
      getMemberBankAccount();
    }
  }, [heartBeat]);

  useEffect(() => {
    if (companyIcons.length > 0) {
      getCompanyIcon();
    }
  }, [companyIcons]);

  const getCompanyIcon = async () => {
    const alert = find(companyIcons, { iconCategory: "alert" });
    if (alert) {
      setAlertIcon(alert.iconImage);
    }
  }

  const submitComplteProfileForm = async (data, e) => {
    let params = {
      //   email: email,
      birthDate: moment(birthDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
    };

    var responseJson = await ApiEngine.post(
      ApiUrl._API_MEMBER_UPDATE_PROFILE,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      bindMember(_dispatch);
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
      _dispatch(setMyProfileModal(false))
    }
  };

  const checkBirthDate = async () => {
    if (
      stringIsNullOrEmpty(memberData.birthDateString) ||
      memberData.birthDateString == "01-01-0001"
    ) {
      if (popout) {
        setShowAlert(true)
      }
      
      setIsEditBirthDate(true)
    } else {
      setIsEditBirthDate(false)
      setShowAlert(false)
    }
  }

  const getMemberBankAccount = async () => {
    try {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_GET_MEMBER_BANK_ACCOUNT_BY_MEMBER
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let bankAccountList = [];
        responseJson[ApiKey._API_DATA_KEY].map((bankAccount) => {
          bankAccountList.push({
            label: bankAccount.bankName + " - " + bankAccount.accountNumber,
            value: bankAccount.id,
            bankId: bankAccount.bankId,
            accountHolderName: bankAccount.accountHolderName,
            accNum: bankAccount.accountNumber,
            bankName: bankAccount.bankName,
            isDefault: bankAccount.isDefault,
          });
        });
        // setMemberBankAccounts(bankAccountList);
        // setSearchMemberBankAccount(bankAccountList);

        setMemberBankAccounts(bankAccountList?.[0]);

      }
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  }

  // Add ewallet functions
  const addEwallet = async (data, e) => {
    let params = {
      accountHolderName: data.accountHolderName,
      phoneNumber: (selectedPhonePrefix == "" ? PhoneNumberPrefix[0].value : selectedPhonePrefix) + formattedPhoneNumber
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_ADD_MEMBER_PHONE_NUMBER,
      createFormBody(params)
    );

    if (typeof responseJson[ApiKey._API_SUCCESS_KEY] !== "undefined") {
      bindMember(_dispatch);
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      if(responseJson[ApiKey._API_SUCCESS_KEY]) {
        setShowAddPhoneNumberModal(false)
        getMemberEwalletAccounts();
      }
    }
  };
  let countdownPeriodTimeOutId,apiTimeOutId;

  // OTP functions
  // useEffect(() => {
  //   if (countdownPeriod > 0) {
  //     countdownPeriodTimeOutId=setTimeout(() => {
  //       let tempPeriod = countdownPeriod - 1;
  //       setCountdownPeriod(tempPeriod);
  //     }, _SECONDS_IN_MS);
  //   }
  // }, [countdownPeriod]);

  // useEffect(() => {
  //   if (TACcountdownPeriod > 0) {
  //     setTACtimer(
  //       setTimeout(() => {
  //         let tempPeriod = TACcountdownPeriod - 1;
  //         setTACCountdownPeriod(tempPeriod);
  //       }, _SECONDS_IN_MS)
  //     );
  //   }
  // }, [TACcountdownPeriod]);

  // const sendOtp = async () => {
  //   const OTP_VIA_SMS = 1;
  //   const channelType = OTP_VIA_SMS;

  //   let params = {
  //     phoneNumber: selectedPhoneNumber,
  //     languageCode: "en",
  //     countdown: countdownPeriod,
  //     TACExist: !TACtimedOut,
  //     type: OTP_VIA_SMS,
  //     usage: "verifyPhoneNumber",
  //   };

  //   let responseJson = await ApiEngine.post(
  //     ApiUrl._API_GENERATE_OTP,
  //     createFormBody(params)
  //   );

  //    if (responseJson[ApiKey._API_SUCCESS_KEY]
  //    ) {
  //     setSentCode(true);
  //     setTACtimedOut(false);
  //     setCountdownPeriod(0);
  //     setCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
  //     setAttemp(attemp + 1);
  //     apiTimeOutId=setTimeout(() => {
  //       setTACtimedOut(true);
  //     }, _TAC_VALID_TIMER);
  //   }else{
  //     setShowVerifyOtpModal(false);
  //     _dispatch(
  //       showResponseMessage(
  //         responseJson[ApiKey._API_SUCCESS_KEY],
  //         responseJson[ApiKey._API_MESSAGE_KEY]
  //       )
  //     );
  //   }
  // }

  // const stopTimeout = () => {
  //   clearTimeout(countdownPeriodTimeOutId);
  //   clearTimeout(apiTimeOutId);
  // };

  const handleVerifyOtp = async (data, e) => {
    // Reset send OTP modal
    setSentCode(false);
    setTACtimedOut(true);
    setCountdownPeriod(0);

    // Open modal
    setShowVerifyOtpModal(true);
  }

  // Old code - from withdrawal, not in use anymore
  // const sendVerificationCode = async () => {
  //   await ApiEngine.post(
  //     ApiUrl._API_SEND_VERIFICATION_CODE,
  //     createMultiPartFormBody({ phoneNumber: selectedPhoneNumber })
  //   ).then((response) => {
  //     if (response[ApiKey._API_SUCCESS_KEY]) {
  //       setSentCode(true);
  //       setCountdownPeriod(_COUNTDOWN_PERIOD);
  //     }
  //   });
  // };

  // async function onKeyPress(key, index) {
  //   //setAllowPinClick(false);
  //   // 1st: have to check currentPinRef
  //   // 2nd: have to know which index is currently focused for altering the state.value
  //   // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
  //   // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin
  //   // let currentIndex = 0;
  //   let pinStr = tempPinNumber;
  //   let keyLength = key.split("");
  //   if (key !== "{bksp}" && key !== "&nbsp;") {
  //     pinStr = key;
  //     setTempPinNumber(pinStr);
  //     if (keyLength.length !== 6) {
  //     } else {
  //       setTempPinNumber("");
  //       setPinInputDisabled(true);
  //       setPinLoading(true);

  //       await verifyOTP(pinStr);

  //       setPinLoading(false);
  //       setPinInputDisabled(false);
  //     }
  //   } else {
  //     elePin["current"].clear();
  //   }
  // }

  // const verifyOTP = async (pinstr) => {
  //   await ApiEngine.post(
  //     ApiUrl._API_VERIFY_MULTIPLE_PHONE_NUMBER,
  //     createMultiPartFormBody({
  //       verificationCode: pinstr,
  //       phoneNum:selectedPhoneNumber,
  //       phoneNumType:phoneNumType
  //     })
  //   ).then((response) => {
  //     if (response[ApiKey._API_SUCCESS_KEY]) {
  //       setShowVerifyOtpModal(false);
  //       getMemberEwalletAccounts();

  //       if(phoneNumType=="member"){
  //         memberData.isPhoneVerify=true;
  //       }
  //     }

  //     _dispatch(
  //       showResponseMessage(
  //         response[ApiKey._API_SUCCESS_KEY],
  //         response[ApiKey._API_MESSAGE_KEY]
  //       )
  //     );
  //   });
  // };

  const handleVerifyOtpCallback = async (response) => {
    setShowVerifyOtpModal(false);
    getMemberEwalletAccounts();

    if(phoneNumType=="member"){
      memberData.isPhoneVerify=true;
    }
  };

  function sanitizePhoneNumber(e) {
    const re = /^[0-9\b]+$/;
    let value = e.target.value;

    // Check if the value contains only digits
    if (re.test(value)) {
      // Limit the value to 12 characters
      if (value.length > 12) {
        value = value.slice(0, 12);
      }

      // setIsDigit(true);
      setFormattedPhoneNumber(value);
    } else {
      return false;
      // setIsDigit(false);
    }
  }
  
  const getMemberEwalletAccounts = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_PHONE_NUMBERS);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let memberEwalletAccounts = responseJson[ApiKey._API_DATA_KEY];
      setMemberEwalletAccounts(memberEwalletAccounts);
    }
  }

  const deletePhoneNumber = async () => {
    let responseJson = await ApiEngine.post(
      ApiUrl._API_DELETE_MEMBER_PHONE_NUMBER, 
      createFormBody({ phoneNumberId: selectedPhoneNumberId })
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      getMemberEwalletAccounts();
    }
  }

  return (
    <div>
      {showAlert && (
        <div className="sweet-alert-container t3-sweet-alert-container">
          <SweetAlert
            cancelBtnText={t("CANCEL")}
            confirmBtnText={t("CONTINUE")}
            icon
            custom
            showCancel
            reverseButtons={true}
            // confirmBtnBsStyle="primary"
            cancelBtnBsStyle="danger"
            customIcon={(
              <img
                className="profile-sweet-alert-icon"
                src={alertIcon}
                alt="Custom Icon"
              />
            )}
            customButtons={
              <React.Fragment>
                <div className="sweet-alert-button-container">
                  <button
                    className="btn btn-lg btn-danger mt-2"
                    onClick={() => setShowAlert(false)}
                  >
                    {t("LATER")}
                  </button>
                  <button
                    className="btn btn-lg btn-primary custom-sweetalert-btn-margin mt-2"
                    onClick={() => { _dispatch(setMyProfileModal(true)); setShowAlert(false); }}
                  >
                    {t("CONTINUE")}
                  </button>
                </div>

              </React.Fragment>
            }
          >
            <span className="sweet-alert-custom-text mt-3">
              {t("COMPLETE_PROFILE_INFO")}
            </span>
            <div className="mt-3 mb-3 reminder-red">
              <div className="first">
                <div className="first">
                  <img
                    src={require("../../assets/img/new_icons/red.png")}
                    className="img-responsive"
                    alt="icon"
                  />
                </div>
                <div>
                  <p className="text-left font14 mb-0 f-weight-500">
                    {t("COMPLETE_PROFILE_DANGER")}
                  </p>
                </div>
              </div>
            </div>
          </SweetAlert>
        </div>
      )}

      <Modal
        id="t3-custom-modal"
        className="modal-custom-fullscreen"
        contentClassName={"t3-custom-modal t3-custom-modal-with-back-btn min-height-600px"}
        fade={false}
        isOpen={profileModal}
        toggle={() => {
          _dispatch(setMyProfileModal(false))
        }}
      >
        <ModalHeader>
          <div className="first">
            <div
              onClick={() => {
                // setManageProfileShow(false);
                _dispatch(setMyProfileModal(false))

              }}
            >
              <VIcon
                icon={themeIcons.angleLeft}
                className="vicon theme-icon-size-20"
              />
            </div>
            <div>{t("MY_PROFILE")}</div>
          </div>
          <div className="second">
            <div
              className="t3-close-custom-modal"
              onClick={() => _dispatch(setMyProfileModal(false))}
            >
              <VIcon icon={themeIcons.close} className="vicon" />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          {memberData ? (
            <div className="t3-custom-modal-container">
              <div className="custom-body edit-profile" >
                <form
                  onSubmit={completeProfileHandleSubmit(submitComplteProfileForm)}
                >
                  <h5>
                    {t("GENERAL")}
                  </h5>
                  <div className="t3-settings-ep-username-box">
                    <div className="item edit-profile-align">
                      <VIcon
                        icon={themeIcons.user}
                        className="vicon theme-icon-size-20"
                      />
                      <div className="ml-3">{memberData.username}</div>
                    </div>
                    {
                      memberData && !isEmpty(memberData?.userFullName) && (
                        <div className="item edit-profile-align">
                          <VIcon
                            icon={themeIcons.user}
                            className="vicon theme-icon-size-20"
                          />
                          <div className="ml-3">{memberData?.userFullName}</div>
                        </div>
                      )
                    }
                    
                    {
                      isEditBirthDate ?
                        (
                          <>
                            <div className="item edit-profile-align" style={memberData.birthDateString != "01-01-0001" && memberData.birthDateString != "" ? null : { padding: "6px 6px 6px 20px" }}>
                              <VIcon
                                icon={themeIcons.calender4}
                                className="vicon theme-icon-size-20"
                              />
                              {memberData.birthDateString != "01-01-0001" && memberData.birthDateString != "" ?
                                <div className="ml-3">{memberData.birthDateString}</div>
                                :
                                <DatePicker
                                  singleDatePicker={true}
                                  onApply={(event, picker) => {
                                    setBirthDate(
                                      moment(picker.startDate).format("DD-MM-YYYY")
                                    );
                                  }}
                                  alwaysShowCalendars={true}
                                  locale={{ 
                                    applyLabel: t("APPLY"),
                                    cancelLabel: t("CANCEL"),
                                  }}
                                  showDropdowns={true}
                                  opens="center"
                                  containerStyles={{ height: "10%", width: "100%", padding: "0px 15px 0px 0px" }}
                                  minDate={minDate}
                                  maxDate={maxDate}
                                  startDate={moment().subtract(18, "years")}
                                >
                                  <input className="ml-3 t3-custom-input-text-profile profile-input" name="birthDate" id="birthDate" type="text" placeholder={t("ENTER_YOUR_DOB")} value={birthDate}></input>
                                </DatePicker>
                              }
                            </div>
                            <div className="d-flex align-items-center user-available mb-2 mt-2">
                              <div className="first">
                                <img
                                  src={require("../../assets/img/new_icons/red.png")}
                                  className="img-responsive"
                                  alt="icon"
                                />
                              </div>
                              <div className="font12 text-lgray3 second text-customred">{t("FILL_IN_BIRTHDAY_INFO")}</div>
                            </div>
                          </>
                        ) :
                        (
                          <div className="item">
                            <VIcon
                              icon={themeIcons.calender4}
                              className="vicon theme-icon-size-20"
                            />
                            <div className="ml-3">{memberData.birthDateString}</div>
                          </div>
                        )
                    }
                    <h5 className="mt-4">
                      {t("CONTACT_DETAILS")}
                    </h5>
                    {memberData ? (
                      <>
                        <div className="item edit-profile-align justify-content-between">
                          <div className="d-flex align-items-center">
                            <VIcon
                              icon={themeIcons.profilePhone}
                              className="vicon theme-icon-size-20 mr-3"
                            />
                            {memberData.phoneNumber ?? "-"}
                            {!memberData.isPhoneVerify &&(<div 
                              className="ml-3 verify-otp"
                              onClick={() => {
                                setSelectedPhoneNumber(memberData.phoneNumber);
                                setPhoneNumType("member");
                                handleVerifyOtp();
                              }}
                            >
                              {t("VERIFY_OTP")}
                            </div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <Skeleton height={40} baseColor="#333" highlightColor="#636363" />
                    )}
                    {
                      memberBankAccounts && (
                        <>
                          <h5 className="mt-4">
                            {t("BANK")}
                          </h5>
                          <div className="t3-settings-ep-username-box">
                            <div className="item">
                              <VIcon
                                icon={themeIcons.user}
                                className="vicon theme-icon-size-20"
                              />
                              <div className="ml-3">{memberBankAccounts.bankName}</div>
                            </div>

                            <div className="item">
                              <VIcon
                                icon={themeIcons.settingsBank}
                                className="vicon theme-icon-size-20"
                              />
                              <div className="ml-3">{memberBankAccounts.accountHolderName}</div>
                            </div>
                            <div className="item">
                              <VIcon
                                icon={themeIcons.settingsBank}
                                className="vicon theme-icon-size-20"
                              />
                              <div className="ml-3">{memberBankAccounts.accNum}</div>
                            </div>


                          </div>
                        </>
                      )
                    }

                    <h5 className="mt-4">
                      {t("EWALLET_ACCOUNTS")}
                    </h5>
                    {/* EWallet Account (Phone Numbers) Section */}
                    {memberEwalletAccounts && memberEwalletAccounts.length > 0 && (
                      <div id="t3-ewallet-accounts">
                        {memberEwalletAccounts.map((item, index) => (
                          <div className="item edit-profile-align justify-content-between" style={{ padding: "10px 20px" }} key={index}>
                            <div className="d-flex align-items-center">
                              <VIcon
                                icon={themeIcons.wallet}
                                className="vicon theme-icon-size-20 mr-3"
                              />
                              <div className="wallet-info">
                                <div className="phone-number">
                                  {item.phoneNumber}
                                  {!item.verified &&(
                                    <div 
                                      className="ml-3 verify-otp"
                                      onClick={() => {
                                        setSelectedPhoneNumber(item.phoneNumber);
                                        setPhoneNumType("memberEwalletAccounts");
                                        handleVerifyOtp();
                                      }}
                                    >
                                      {t("VERIFY_OTP")}
                                    </div>
                                  )}
                                </div>
                                <div className="account-name">{t("ACCOUNT_HOLDER")}: {item.accountHolderName ? item.accountHolderName : "-"}</div>
                              </div>
                            </div>
                            <div className="delete-phone-number" onClick={() => { 
                              setSelectedPhoneNumber(item.phoneNumber);
                              setSelectedPhoneNumberId(item.id);
                              setShowDeletePhoneNumberModal(true); 
                            }}>
                              <VIcon
                                icon={themeIcons.delete}
                                className="vicon theme-icon-size-20"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Add Phone Number Button */}
                    {memberEwalletAccounts && memberEwalletAccounts.length < 3 && (
                      <div className="t3-settings-button-box m-t-10 d-flex justify-content-start">
                        <button type="button" className="t3-custom-light-btn" onClick={() => setShowAddPhoneNumberModal(true)}>
                          {t("ADD_PHONE_NUMBER")}
                        </button>
                      </div>
                    )}

                    { isEditBirthDate &&
                      <div className="t3-settings-button-box mt-5 d-flex justify-content-center">
                        <button type="submit" className="t3-custom-light-btn ">
                          {t("SAVE_CHANGES")}
                        </button>
                      </div>
                    }
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <Skeleton height={200} baseColor="#333" highlightColor="#636363" />
          )}
        </ModalBody>
      </Modal>

      {/* Add new phone number modal */}
      <Modal
        className="modal-custom-fullscreen"
        contentClassName={"t3-custom-modal t3-custom-modal-with-back-btn"}
        fade={false}
        isOpen={showAddPhoneNumberModal}
        toggle={() => {
          setShowAddPhoneNumberModal(false)
        }}
      >
        <ModalHeader>
          <div className="first">
            {/* <div
              onClick={() => {
                // setManageProfileShow(false);
                _dispatch(setMyProfileModal(false))

              }}
            >
              <VIcon
                icon={themeIcons.angleLeft}
                className="vicon theme-icon-size-20"
              />
            </div> */}
            <div>{t("ADD_PHONE_NUMBER")}</div>
          </div>
          <div className="second">
            <div className="t3-close-custom-modal" onClick={() => setShowAddPhoneNumberModal(false)}>
              <VIcon icon={themeIcons.close} className="vicon" />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="t3-custom-modal-container">
            <div className="modal-body">
              <div>
                <div className="d-flex flex-column align-items-center justify-content-center user-available p-20">
                  <form
                    className="w-100"
                    onSubmit={ewalletHandleSubmit(addEwallet)}
                  >
                    {/* <div className="t3-pin-title2 mb-3">
                      {t("ADD_NEW_SECONDARY_PHONE_NUMBER")}
                    </div> */}
                    <div className="t3-input-container">
                      <div className="t3-input-container">
                        <label>{t("ACCOUNT_HOLDER_NAME")}</label>
                      </div>
                      <div className="t3-contact-number-box">
                        <div className="second">
                          <input
                            name="accountHolderName"
                            className="t3-custom-input-text"
                            ref={ewalletRegister({
                              required: true,
                            })}
                            errors={ewalletErrors}
                            onChange={(e) => sanitizePhoneNumber(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="t3-input-container mt-3">
                      <div className="t3-input-container">
                        <label>{t("MOBILE_NUMBER")}</label>
                      </div>
                      <div className="t3-contact-number-box">
                        <div className="first">
                          <select
                            onChange={(e) => {
                              setSelectedPhonePrefix(e.target.value);
                            }}
                          >
                            {PhoneNumberPrefix.map((prefix, index) => {
                              return (
                                <option
                                  key={index}
                                  className={"phone-prefix-" + (index + 1)}
                                  value={prefix.value}
                                >
                                  {prefix.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="second">
                          <input
                            name="registerPhone"
                            className="t3-custom-input-text"
                            autoComplete="new-password"
                            ref={ewalletRegister({
                              required: true,
                              minLength: {
                                value: 8,
                                message: t("MIN_CHARACTERS", { length: 8 }),
                              },
                              maxLength: {
                                value: 11,
                                message: t("MAX_CHARACTERS", { length: 11 }),
                              },
                              pattern: {
                                value: /^[0-9]+$/g,
                                message:
                                  t("MIN_CHARACTERS", { length: 8 }) +
                                  " " +
                                  t("AND") +
                                  " " +
                                  t("ONLY_NUMBERS"),
                              },
                            })}
                            errors={ewalletErrors}
                            onKeyDown={(e) => {
                              const key = e.key;
                              const allowedKeys = [
                                "Backspace",
                                "ArrowLeft",
                                "ArrowRight",
                                "Delete",
                                "Tab",
                              ];

                              if (
                                !/[0-9]/.test(key) &&
                                !allowedKeys.includes(key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => sanitizePhoneNumber(e)}
                          />

                          {ewalletError == true ? (
                            <div className="d-flex align-items-center user-available">
                              <div className="font12 text-lgray3 second text-customred">
                                {t("NUMBER_NOT_AVAILABLE")}
                              </div>
                            </div>
                          ) : ewalletErrors.registerPhone ? (
                            <div className="d-flex align-items-center user-available">
                              <div className="font12 text-lgray3 second text-customred">
                                {t(ewalletErrors.registerPhone.message)}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button 
                        className="t3-custom-light-btn mt-4"
                        style={{ width: "200px" }}
                      >
                        {t("SAVE")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <VerifyPhoneNumberModal 
        show={showVerifyOtpModal}
        onClose={() => setShowVerifyOtpModal(false)}
        phoneNumber={selectedPhoneNumber}
        phoneNumType={phoneNumType}
        onCallback={(e) => {
          console.log(e);
          handleVerifyOtpCallback(e)
        }}
      />

      <SweetAlert
        // title={t("DELETE_PHONE_NUMBER")}
        show={showDeletePhoneNumberModal}
        icon
        custom
        showCancel
        reverseButtons={true}
        // confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        customIcon={(
          <img
            className="profile-sweet-alert-icon"
            src={alertIcon}
            alt="Custom Icon"
          />
        )}
        customButtons={
          <React.Fragment>
            <div className="sweet-alert-button-container mt-2">
              <button
                className="btn btn-lg btn-secondary"
                onClick={() => setShowDeletePhoneNumberModal(false)}
              >
                {t("CANCEL")}
              </button>
              <button
                className="btn btn-lg btn-danger"
                onClick={() => { deletePhoneNumber(); setShowDeletePhoneNumberModal(false); }}
              >
                {t("DELETE")}
              </button>
            </div>
            
          </React.Fragment>
        }
      >
        <span className="sweet-alert-custom-text mt-3">
          {t("DELETE_PHONE_NUMBER_CONFIRMATION")}
        </span>
        <div className="mt-3 mb-3 reminder-red">
          <div className="first">
            <div className="first">
              <img
                src={require("../../assets/img/new_icons/red.png")}
                className="img-responsive"
                alt="icon"
              />
            </div>
            <div>
              <p className="text-left font14 mb-0 f-weight-500">
                {t("DELETE_PHONE_NUMBER_DANGER")}
              </p>
            </div>
          </div>
        </div>
      </SweetAlert>
    </div>

  );
};

export default MyProfileModal;
