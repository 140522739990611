import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../../util/Constant";
import { useTranslation } from "react-i18next";
import {
	setBusy,
	setIdle,
	showResponseMessage,
	setPageSize,
	setY,
	setActiveTab,
	setReportOverviewModal,
} from "../../../redux/AppAction.js";
import { useForm, Controller } from "react-hook-form";
import ApiEngine from "../../../util/ApiEngine.js";
import DynamicLoadTable from "../../../components/custom/DynamicLoadTable";
import StyledSelect from "../../../components/custom/StyledSelect";
import { Modal, ModalBody, Spinner, ModalHeader } from "reactstrap";
import classnames from "classnames";
import {
	createFormBody,
	createMultiPartFormBody,
	stringIsNullOrEmpty,
} from "../../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";
import ToggleButton from "react-toggle-button";
import { map } from "lodash";
import ContentHeader from "../../../components/contentheader/header";
import moment from "moment";
import DateRangeFilter from "components/custom/DateRangeFilter";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import TransactionHistory from "../report/TransactionHistory";
import GameHistory from "../report/GameHistory";
import CommissionHistory from "../report/CommissionHistory";
import RebateHistory from "../report/RebateHistory";
import DailyCheckInHistory from "./DailyCheckInHistory";

const ReportOverview = (props) => {
	var { t } = useTranslation(LanguageKey._PRIMARY);
	var _history = useHistory();
	var _dispatch = useDispatch();
	var _location = useLocation();
	const [loadingSearchData, setLoadingSearchData] = useState(false);
    const dailyCheckInExist = useSelector((state) => state.appState.dailyCheckInExist);

	const recordType = [
		{
			label: "TRANSACTION_RECORD",
			value: 1,
		},
		{
			label: "BET_RECORD",
			value: 2,
		},
	];

	const updatedRecordType = [
		...recordType,
		...(dailyCheckInExist
			? [
					{
						label: "DAILY_CHECK_IN",
						value: 5,
					},
			  ]
			: []),
	];
	const [selectedOption, setSelectedOption] = useState(1);
	const { memberData, recentData } = useSelector(
		(state) => state.appState.downline
	);

	const isReportOverviewModal = useSelector((state) => state.appState.reportOverviewModal);

	useEffect(() => {
		// if (memberData === null && recentData === null) {
		//   _dispatch(setBusy());
		// } else {
		//   _dispatch(setIdle());
		// }
	}, [memberData, recentData]);

	const closeBtn = (
		<div
			className="close"
			onClick={() => _dispatch(setReportOverviewModal(!isReportOverviewModal))}
		>
			<VIcon icon={themeIcons.close} className="vicon" />
		</div>
	);

	return (
		<Modal
			id="t3-custom-modal"
			fade={false}
			className="modal-custom-fullscreen"
			contentClassName={"t3-custom-modal"}
			backdrop={"static"}
			isOpen={isReportOverviewModal}
			toggle={() => {
				// setShowModal(false);
				_dispatch(setReportOverviewModal(!isReportOverviewModal));
			}}
			fullscreen={"true"}
			//   centered
		>
			<ModalHeader close={closeBtn}>{t("HISTORY_RECORD")}</ModalHeader>
			<ModalBody id="modalBody" className="modal-body-dynamic-table">
				<div className="t3-statistics-body-box pb-0">
					<div className="t3-statistics-dropdown-box mb-3 d-block">
						<div className="first mb-3">{t("RECORD_TYPE")}</div>
						<div className="second">
							{!loadingSearchData ? (
								recordType && recordType.length > 0 ? (
									<select
										name="recordType"
										value={selectedOption}
										onChange={(e) => {
											setSelectedOption(e.target.value);
										}}
									>
										{updatedRecordType.map((item, index) => {
											return (
												<option key={index} value={item.value}>
													{t(item.label)}
												</option>
											);
										})}
									</select>
								) : (
									<div className="no-data-text">{t("NO_DATA_FOUND")}</div>
								)
							) : (
								<Spinner
									animation="border"
									role="status"
									style={{
										verticalAlign: "middle",
										width: "1rem",
										height: "1rem",
									}}
								></Spinner>
							)}
						</div>
					</div>
					<hr className="record-type-separator-line" />
				</div>
				{selectedOption == 1 && <TransactionHistory />}
				{selectedOption == 2 && <GameHistory />}
				{selectedOption == 3 && <CommissionHistory />}
				{selectedOption == 4 && <RebateHistory />}
				{selectedOption == 5 && <DailyCheckInHistory />}

			</ModalBody>
		</Modal>
	);
};

export default ReportOverview;
